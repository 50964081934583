import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TokenStorageService } from '../../services/safeguard/token-storage.service';
import { SharedService } from "../../services/shared.service";
import { IdleMonitorService } from '../../services/safeguard/idle-monitor.service';
import { UserContextService } from '../../services/safeguard/user-context.service';
//import { AppConfigService} from '../../services/app-config.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  // hasAdminRole: boolean;
  // hasMonitorRole: boolean;
  // hasPortalRole: boolean;
  // hasBillerRole: boolean;
  // hasAcctAdminRole: boolean;
  // hasUserAdminRole: boolean;
  // hasCsrUserRole: boolean;
  // hasReadOnlyRole: boolean;
  // hasStatsViewerRole: boolean;

  isUserAuthenticated: boolean = false;

  loginEventSubscription: Subscription;
  browserRefreshEventSubscription: Subscription;
  
  appVerion: string = "v.2024-10.1";
  environmentBanner: string = environment.env.banner;
  isProdEnvironment: boolean = environment.production;
  // appRegion: string = !environment.env.name.includes("PROD") ? "@ " + environment.env.name : '';


   constructor(
    private router: Router,
    private sharedService: SharedService, 
    private tokenStorageService: TokenStorageService,    
    private userContextService: UserContextService,
    private idleMonitorService: IdleMonitorService) { 
      this.loginEventSubscription = this.tokenStorageService.getLoginEvent().subscribe((userLoggedIn: boolean)=>{
          this.isUserAuthenticated = userLoggedIn;
      })
      this.browserRefreshEventSubscription = this.tokenStorageService.getBrowserRefreshEvent().subscribe((browserRefresh: boolean) => {
        if (browserRefresh && this.userContextService.isUserAuthenticated()) {
          this.isUserAuthenticated = true;
        }
      })
    }
 
  ngOnInit() {
  }
 
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public isUserAuthenticatedX (): boolean {
    return this.userContextService.isUserAuthenticated();
  }


  // Find out whether the current user has ALL the privileges from the list
  public hasAllPrivileges (priveleges: string[]): boolean {
    return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has at least one privilege from the list
  public hasAnyPrivilege (priveleges: string[]): boolean {
    return this.userContextService.userHasAllPrivileges(priveleges);
  }

  // Find out whether the current user has a required privilege
  public hasPrivilege (privilege: string): boolean {
    // console.log('header.component','hasPrivilege: ' + privilege);
    return this.userContextService.userHasPrivilege(privilege);
  }

  navigateMenu(tag: string){
    if (tag === 'home'){
         this.router.navigate(['/home']);
        }
    if (tag === 'homeBiller'){
          this.router.navigate(['/homeBiller']);
        }
    if (tag === 'portalUsers'){
        this.router.navigate(['/portalUsers']);
        }
    if (tag === 'monitoring'){
        this.router.navigate(['/monitoring']);
       }
    if (tag === 'accountInfo'){
      this.router.navigate(['/accountInfo']);
    }
    if (tag === 'enrollments'){
      this.router.navigate(['/enrollmentReport']);
    }
    if (tag === 'csrAccount') {
      this.router.navigate(['/csrAccount'])
    }
    if (tag === 'logout'){
      // clean up current brand and template
      // this.sharedService.setCurrentPassTemplate(this.sharedService.getDummyPassTemplate());
      // this.sharedService.setCurrentOffer(this.sharedService.getDummyOffer());
      // this.sharedService.setCurrentBrandCode('');
      // this.sharedService.setCurrentBrand(this.sharedService.getDummyBrand());

      this.sharedService.clearUserSession();

      // make the actual logout
      this.idleMonitorService.stopWatching();
      this.tokenStorageService.sendLoginEvent();
      this.tokenStorageService.signOut();
      this.router.navigate(['/login']);
    }
  }

  // private toolbarAuthorization(isUserLoggedIn) {
  //   this.hasAdminRole = false;
  //   this.hasMonitorRole = false;
  //   this.hasPortalRole = false;
  //   this.hasReadOnlyRole = false;
  //   this.hasBillerRole = false;
  //   this.hasAcctAdminRole = false;
  //   this.hasUserAdminRole = false;
  //   this.hasStatsViewerRole = false;
  //   this.isUserAuthenticated = false;
  //   // this.isBillerRep = false;
  //   this.hasCsrUserRole = false;
  //   if (!isUserLoggedIn) {
  //     return;
  //   }

  //   this.isUserAuthenticated = true;

  //   // Hide/show specific toolbars based on the current user's role(s)
  //   var currentUser = this.tokenStorageService.getCurrentUser();
  //   if (currentUser) {
  //     if(currentUser.userName && (Array.isArray(currentUser.userRoleList) && currentUser.userRoleList.length)) {
  //       if (currentUser.userRoleList.indexOf('rl_admin') >= 0) {
  //         this.hasAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_monitor') >= 0) {
  //         this.hasMonitorRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_aci_staff') >= 0 || this.userContextService.isAciStaff()
  //           || this.userContextService.hasReadOnlyAccess()) {
  //         this.hasPortalRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_biller_rep') >= 0) {
  //         this.hasBillerRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_csr_user') >= 0) {
  //         this.hasCsrUserRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_acct_admin') >= 0) {
  //         this.hasAcctAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_user_admin') >= 0) {
  //         this.hasUserAdminRole = true;
  //       } 
  //       if (currentUser.userRoleList.indexOf('rl_aci_read_only') >= 0 || this.userContextService.hasReadOnlyAccess()) {
  //         this.hasReadOnlyRole = true;
  //       } 
  //       // this.isBillerRep = this.userContextService.isBillerRep();
  //       if (currentUser.userRoleList.indexOf('rl_stats_viewer') >= 0) {
  //         this.hasStatsViewerRole = true;
  //       } 
  //     }
  //   }
  // }
  

}
