import { IOfferField } from '../../interfaces/offer-field';
import { OfferFieldBase} from '../offer-field-base';
import { OfferFieldAttribute } from '../offer-field-attribute';

export class OfferFieldNotifPrefEditor extends OfferFieldBase {

    fieldAlignmentOptions: any[];
    fieldFormatOptions: any[];
    brandVariables: any[] = [];
    linkCategories: any[];

    constructor(
        offerFieldData: IOfferField,
        offerFieldTypes: any[],
        fieldAlignmentOptions: any[],
        fieldFormatOptions: any[],
        linkCategories: any[]
        ) { 
            super(offerFieldData, offerFieldTypes);
            this.data = offerFieldData;
            this.fieldAlignmentOptions = fieldAlignmentOptions;
            this.fieldFormatOptions = fieldFormatOptions;
            this.linkCategories = linkCategories;
            this.setOfferFieldAttributes();
        } 

    public setOfferFieldAttributes() {

        // Field Key, Field Value, Offer Link (N), Field Alignment (N), Field Data Type and Style N), Var Transformation Expression, 
        // Show/hide Field Expression (N), Expiration Date (N), Relevant Date (N)

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textarea',
            key: 'fieldValue',
            label: 'Field Value',
            value: this.data.fieldValue,
            styleWidth: '40em',
            required: true,
            rows: 1,
            cols: 36,
            order: 4
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'offerlink',
            label: 'Link',
            value: this.data.hasOwnProperty('offerlink') ? this.data.offerlink : '',
            required: false,
            tooltip: 'URL link',
            styleWidth: '40em',
            validator: 'url',
            order: 5
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'linkCategoryCode',
            label: 'Link Category',
            value: this.data.linkCategoryCode,
            styleWidth: '20em',
            required: false,
            tooltip: 'Select the proper link category',
            options: this.linkCategories,
            order: 6
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'positionSpec',
            label: 'Position Spec',
            value: this.data.positionSpec,
            styleWidth: '40em',
            required: false,
            tooltip: 'Position specification for Google and/or Samsung devices',
            order: 6
        }));
        
        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldAlignment',
            label: 'Field Alignment',
            value: this.data.fieldAlignment,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field allignment on the pass',
            options: this.fieldAlignmentOptions,
            order: 7
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'fieldFormat',
            label: 'Field Data Type and Style',
            value: this.data.fieldFormat,
            styleWidth: '15em',
            required: false,
            tooltip: 'Select the proper field data type and style on the pass',
            options: this.fieldFormatOptions,
            order: 8
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'dropdown',
            key: 'varTransformationExpression',
            label: 'Var Transformation Expression',
            value: this.data.varTransformationExpression,
            styleWidth: '20em',            
            required: true,
            tooltip: 'Select the proper var transformation expression',
            options: [
                {label: '[none]', value: ''},
                {label: 'notifScheduleDescr',  value: 'notifScheduleDescr'}
            ],
            order: 9
        }));

        this.offerFieldAttributes.push( new OfferFieldAttribute({
            controlType: 'textbox',
            key: 'fieldVisibilityExpression',
            label: 'Show/hide Field Expression',
            value: this.data.fieldVisibilityExpression,
            required: false,
            styleWidth: '30em',
            order: 10
        }));

        // this.offerFieldAttributes.push( new OfferFieldAttribute({
        //     controlType: 'textbox',
        //     key: 'expirationDate',
        //     label: 'Expiration Date',
        //     value: this.data.offerFieldId ? this.data.offerFieldId.toString() : '',
        //     required: false,
        //     readonly: true,
        //     styleWidth: '15em',
        //     order: 10
        // }));

        // this.offerFieldAttributes.push( new OfferFieldAttribute({
        //     controlType: 'textbox',
        //     key: 'relevantDate',
        //     label: 'Relevant Date',
        //     value: this.data.offerFieldId ? this.data.offerFieldId.toString() : '',
        //     required: false,
        //     readonly: true,
        //     styleWidth: '15em',
        //     order: 11
        // }));

        // this.offerFieldAttributes.push( new OfferFieldAttribute({
        //     controlType: 'textbox',
        //     key: 'deleteDate',
        //     label: 'Delete Date',
        //     value: this.data.offerFieldId ? this.data.offerFieldId.toString() : '',
        //     required: false,
        //     readonly: true,
        //     styleWidth: '15em',
        //     order: 12
        // }));

        this.offerFieldAttributes.sort((a, b) => a.order - b.order);

    }

}
